import React from "react"
import { Link, graphql } from "gatsby"
import { Container } from 'reactstrap'
import Img from "gatsby-image"
import { css } from "@emotion/core"
import classNames from 'classnames'

import Layout from "../components/layout"
import SEO from "../components/seo"

function cardForCoffee(product) {
  const { frontmatter: { inStock } } = product
  return (
    <div key={product.fields.slug} className="col">
      <Link
        to={product.fields.slug}
        className="card-link"
        css={css`
          text-decoration: none;
          color: inherit;
          &:hover {
            text-decoration: none;
            color: inherit;
          }
        `}
      >
        <div key={product.fields.slug}
          className={
            classNames('card', 'm-1', 
              {'bg-dark': !inStock, 'text-white': !inStock }, 
              {'bg-light': inStock }
            )
          } 
          css={css`
          border: 1em;
          width: 15rem;
          height: 8rem;
        `}>
        {product.frontmatter.featuredImage && 
          <Img class="card-img" css={css`
            opacity: 0.3;
          `}
            fluid={product.frontmatter.featuredImage.childImageSharp.fluid}/>
        }
        <div className="card-img-overlay">
          <h5 className="card-title">{product.frontmatter.title}
          </h5>
          <h6 className="card-subtitle">Roasted {product.frontmatter.date}</h6>
          <p className="card-text">{product.frontmatter.summary}
          {!inStock && <span className="badge badge-pill badge-light">Out of stock</span>}
          </p>
        </div>
      </div>
    </Link>
  </div>
  )
}

export default function Index({ data }) {
  const { edges: products } = data.allMarkdownRemark
  const { coverImage } = data
  // console.log('index.js data', data)

  return (
    <Layout headerImage={coverImage.childImageSharp.fluid}>
      <SEO title="Roasted coffee" keywords={[`Tangerine Fix`, `coffee`]} />
      <Container>
        <div className="row">
          <p className="lead">These are roasted by hand in small batches with attention to detail.</p>
        </div>
        <div className="row">
          {products
            .filter(({ node: product }) => !product.frontmatter.inStock)
            .map(({ node: product }) => {
              // console.log('product', product)
              return cardForCoffee(product)
            })
          }
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProductCoffeeArchivePageQuery {
    coverImage: file(
      relativePath: { regex: "/IMG_2350c/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 1024
          quality: 80
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fields: { collection: { eq: "roast" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            inStock
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 412) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
 `